export const baseUrl = "https://api-dev-s3.nasonline.id";
export const urlLPJK = "http://dev.siki.pu.go.id/siki-api";
export const path_forgotPass = "/public/users/forgot_password";
export const path_notif = "/me/notifications";
export const path_users = "/users";
export const path_admin = "/admins";
export const path_management = "/managements";
export const path_tuk = "/public/tuks";
export const path_tukAdd = "/tuks";
export const path_adminTUK = "/admintuk";
export const path_HomeTuk = "/tuk";
export const path_pleno = "/plenos";
export const path_competenceField = "/competence_fields";
export const path_schema = "/schemas";
export const path_schemaViews = "/schemas/views";
export const path_subSchema = "/sub_schemas";
export const path_applicant = "/applicants";
export const path_applicantGeneral = "/users/applicants";
export const path_assessments = "/assessments";
export const path_draftAssessment = "/drafts/assessments";
export const path_assessmentsDashboard = "/dashboards/assessments";
export const path_GET_schedule_activity = "/me/schedules/assessments";
export const path_POST_article = "/articles";
export const path_GET_article = "/public/articles";
export const path_assign_assessors = "/find_assessor_not_assign"; //Used in Schedule assessment
export const path_assign_asesi = "/find_applicant_not_assign"; //Used in Schedule assessment
export const path_assign_admins = "/find_admin_not_assign"; //Used in Schedule assessment
export const path_recordArchive = "/schedules/assessments";
export const path_letters = "/letters";
export const path_alumni = "/alumnis";
export const path_certificate = "/certificates";
export const path_accessors = `/accessors`; //GET detail,PUT,DELETE Accessor
export const path_accessorsGeneral = "/users/accessors"; //GET,POST Accessor
export const path_accessorCompetence = "/accessor/competences";
export const path_accessorsSchedule = "/accessor/schedules"; //Used in schedule accessors
export const path_accessorsSkill = "/Assessors/list-skill";
export const path_masterData = "/portfolios";
export const path_masterData_umum = "/portfolios-umum";
export const path_materi_uji = "/materi-uji";
export const path_refreshToken = "/me/refresh_token/";
export const path_manageSurat = "/letters";
export const path_unitCompetention = "/unit_competences";
export const path_jointRequest = "/join_requests";
export const path_archive = "/archives";
export const path_persyaratanUmum = "/persyaratan_umum";
export const path_persyaratanUmum_otherAsesi = "/persyaratan_umums";
export const path_jobs = "/jobs";
export const path_religion = "/religion";
export const path_deletePermanenAssessment = "/deleted/assessments";
export const path_restoreAssessment = "/restore/assessments";
